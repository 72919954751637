<template>
  <div class="cases_user">
    <img class="p__c_lpc mr20" :src="`${casesInfo.photo ? $PicPrefix + casesInfo.photo : defPhoto}`" alt="">
    <div class="p__c_lc">
      <h5 class="p__c_lc_name">
        {{casesInfo.realName}}
        <span
          class="lc_name_icon"
          :style="{backgroundImage: `url('${casesInfo.sex === '1' ? BOY_ICON : GIRL_ICON}')`}"
        ></span>
      </h5>
      <p class="p__c_lc_p">
        <span class="r_sr_icon"></span>
        {{casesInfo.age}}（{{casesInfo.birthdayStr}}）
        <span class="r_m_icon"></span>
        {{casesInfo.mobile}}
      </p>
      <!--<p class="p__c_lc_p">-->
        <!--<span class="r_d_icon"></span>-->
        <!--{{casesInfo.address}}-->
      <!--</p>-->
    </div>
  </div>
</template>

<script>
  import {defPhoto, BOY_ICON, GIRL_ICON} from 'common/js/requireImage';
  import {mapGetters} from 'vuex';

  export default {
    data() {
      return {
        defPhoto,
        BOY_ICON,
        GIRL_ICON,
        casesInfo: {}
      }
    },
    computed:{
      ...mapGetters({
        'commonCaseDetail': 'getCommonCaseDetail',//病例详情
      }),
    },
    created() {
      const casesDetail = this.commonCaseDetail.detail;
      if (casesDetail.id) {
        const {productName, clinicId, clinicName, caseNumber} = casesDetail;
        const {realName, photo, address = {}, age, birthdayStr, mobile, sex} = casesDetail.userInfo;
        const {provinceName = '', cityName = '', areaName = ''} = address;
        this.casesInfo = {
          caseNumber,
          clinicName,
          clinicId,
          productName,
          realName,
          photo,
          address: `${provinceName}-${cityName}-${areaName}-${address.address || ''}`,
          age,
          sex,
          birthdayStr,
          mobile
        };
      }
    }
  }
</script>

<style scoped lang="scss">
  .cases_user{
    flex: 1;
    margin-right: 0.2rem;
    background-color: #fff;
    border-radius: 0.08rem;
    display: flex;
    align-items: center;
    .p__c_lpc{
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
    }
    .p__c_lc{
      .p__c_lc_name{
        font-size: 0.2rem;
        margin-bottom: 0.1rem;
        display: flex;
        align-items: center;
        color: $main_theme_color_333;
        .lc_name_icon{
          width: 0.18rem;
          height: 0.18rem;
          margin-left: 0.08rem;
          background-size: 100% 100%;
        }
      }
      .p__c_lc_p{
        display: flex;
        color: $main_theme_color_333;
        line-height: 0.24rem;
        font-size: 16px;
        &:nth-of-type(1){
          margin-bottom: 0.1rem;
        }
      }
    }
  }
</style>
