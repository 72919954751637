var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"do_keep"},[_c('back-step',{attrs:{"goList":[{
      url: '/cases',
      name: _vm.$t('casesDetail.casesChildren.common.bl')
    }, {
      url: ("/cases/detail?caseId=" + (_vm.$route.query.caseId) + "&curePlanId=" + (_vm.$route.query.curePlanId)),
      name: _vm.$t('casesDetail.casesChildren.common.blxq')
    }],"currentTxt":_vm.type === 'done' ? _vm.$t('casesDetail.casesChildren.casesDone.wcbl') : _vm.$t('casesDetail.casesChildren.casesDone.gdbl')}}),_c('div',{staticClass:"plan_head"},[_c('span',{staticClass:"plan_h_strong"},[_vm._v(_vm._s(_vm.$t('casesDetail.casesChildren.casesDone.blh'))+"："+_vm._s(_vm.casesInfo.caseNumber))]),_c('span',{staticClass:"plan_h_sp"},[_vm._v(_vm._s(_vm.casesInfo.clinicName))]),_c('span',{staticClass:"plan_h_sp"},[_vm._v(_vm._s(_vm.casesInfo.productName))])]),_c('div',{staticClass:"cases_d_box"},[(_vm.commonCaseDetail.state !== 'loading')?_c('cases-user-msg'):_vm._e(),_c('div',{staticClass:"cases_d_foo"},[_c('span',{staticClass:"submit_btn flex-x-y-c curp ml36",on:{"click":_vm.toDone}},[_vm._v(_vm._s(_vm.$t('casesDetail.casesChildren.common.qr')))]),_c('span',{staticClass:"back_btn flex-x-y-c curp",on:{"click":function($event){return _vm.goPage('/cases/detail')}}},[_vm._v(_vm._s(_vm.$t('casesDetail.casesChildren.common.fh')))])])],1),_c('confirm-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],attrs:{"confirmObj":{
      tipText: _vm.$t('casesDetail.casesChildren.common.qrjx'),
      backText: _vm.$t('casesDetail.casesChildren.common.qx'),
      okText: _vm.$t('casesDetail.casesChildren.common.qd')
    }},on:{"confirmSubmit":_vm.confirmSubmit,"confirmHide":_vm.confirmHide}}),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }